.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #7400B8;
    padding: 0vmax 1vmax 2vmax 1vmax;
    box-sizing: border-box;
    text-align: center;
    color: #fff;
}

.contentDiv{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
    background-color: #7400B8;
    padding: 0 20px;
    box-sizing: border-box;
    text-align: center;
    color: #fff;
}

.contentDiv>p{
    margin-top: 0.5vmax;
    padding: 0;
}

.header_pera{
    font-size: 1.2rem;
    width: 100%;
    text-align: center;
}

.content_pera{
    font-size: 1rem;
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
}


.question_answer_list{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
    background-color: #7400B8;
    padding: 0 20px;
    box-sizing: border-box;
    color: #fff;
}

.question_answer_list>p>li{
    list-style: none;
    margin: 0.2vmax;
    padding: 0;
}