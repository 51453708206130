.base {
    display:flex;
}
.container{
    margin-top: 1vh;
    margin-left: 2vw;
    
    overflow: scroll;
  
    border-radius: 5px;
    }

    .tab {
      
        
         border-collapse : separate;
         border-spacing: 20px;
    } 
 

  


    