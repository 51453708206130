
.fullname{
    background: white;
    border: none;
    outline: none;
    width: 20vw;
    height: 6vh;
    &::placeholder{
        font-weight: bold;
    }
    padding-left: 10px;
}